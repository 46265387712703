import { Component, Renderer2 } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MixpanelService } from 'taxtank-core/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
@Sentry.TraceClassDecorator()
export class AppComponent {
  constructor(
    private router: Router,
    private renderer: Renderer2,
    private mpService: MixpanelService,
    translate: TranslateService,
  ) {
    // Get loader Html element and hide it
    const loaderElement = this.renderer.selectRootElement('#root-preloader');
    this.renderer.setStyle(loaderElement, 'display', 'none');

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('enum');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');

    this.mixpanelInit();
  }

  /**
   * web analytics
   */
  mixpanelInit(): void {
    this.mpService.init();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.mpService.trackPageView();
      }
    });
  }
}
